import React from "react";

import ExperienceItem from "../components/ExperienceItem";

import ExperienceList from "../data/experience.json";

const Experience: React.FC<{}> = () => {
  return (
    <React.Fragment>
      {ExperienceList.map((category, i) => {
        return (
          <div key={i}>
            <h2>{category.heading}</h2>
            {category.experience.map((experience, j) => {
              return <ExperienceItem key={experience.title} {...experience} />;
            })}
          </div>
        );
      })}
    </React.Fragment>
  );
};

export default Experience;

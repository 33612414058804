import React, { useState } from "react";

import UpChevron from "./chevron-up-outline.svg";
import DownChevron from "./chevron-down-outline.svg";
import Ellipse from "./ellipse-outline.svg";

import styles from "./index.module.css";

interface ExperienceItemProps {
  startDate: string;
  endDate: string;
  title: string;
  company: string;
  details: string[];
  expandable: boolean;
}

const ExperienceItem: React.FC<ExperienceItemProps> = ({
  startDate,
  endDate,
  title,
  company,
  details,
  expandable
}) => {
  const [collapsed, setCollapsed] = useState(true);
  const handleExpand = () => {
    if (expandable) {
      setCollapsed(!collapsed);
    }
  };

  return (
    <div onClick={handleExpand} className={styles.container}>
      <div className={styles.separater}>
        <img src={Ellipse} className={styles.separaterDot} />
        <div className={styles.separaterLine} />
      </div>
      <div className={styles.titleBar}>
        <h3 className={styles.title}>{title}</h3>
        <h4 className={styles.subtitle}>
          {company} | {startDate} - {endDate}
        </h4>
      </div>
      {expandable ? (
        <img className={styles.directional} src={collapsed ? DownChevron : UpChevron} />
      ) : null}
      {!collapsed ? (
        <ul className={styles.details}>
          {details.map((lineItem, i) => {
            return <li key={i}>{lineItem}</li>;
          })}
        </ul>
      ) : null}
    </div>
  );
};

export default ExperienceItem;
